import {Disclaimer} from './disclaimer'

import * as data from '../data'

function mapState(state) {
  return {
    children: data.getLicenseDisclaimer(state),
  }
}

const connector = data.connect(mapState)

export default connector(Disclaimer)
