import PropTypes from 'prop-types'
import React from 'react'

export function Disclaimer(props) {
  if (!props.children) {
    return null
  }

  return (
  <div
    style={{
      position: 'fixed',
      bottom: '0px',
      left: '0px',
      right: '0px',
      paddingTop: '8px',
      paddingBottom: '8px',
      color: 'white',
      backgroundColor: 'rgba(0,0,0,0.5)',
      textAlign: 'center',
      fontFamily: 'Verdana',
      fontSize: '12px',
      animation: 'fade-out 8s ease forwards',
      pointerEvents: 'none',
    }}
    >
      {props.children}
    </div>
  )
}

Disclaimer.propTypes = {
  children: PropTypes.node,
}
